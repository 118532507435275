import React, {useState} from 'react';
import Footer from '../components/Footer';
import LandingSection from '../components/LandingSection';
import InfoSection from '../components/InfoSection';
import { aboutMeObj, educationObj, cvObj } from '../components/InfoSection/Data';
import Navbar from '../components/Navbar';
import Projects from '../components/Projects';
import Sidebar from '../components/Sidebar';

const Home = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle}/>
      <Navbar toggle={toggle} />
      <LandingSection />
      <InfoSection {...aboutMeObj}/>
      <InfoSection {...educationObj} />
      <InfoSection {...cvObj} />
      <Projects />
      <Footer toggle={toggle}/>
    </>
  )
}

export default Home
