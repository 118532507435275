export const aboutMeObj = {
  id: 'about',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Who am I?',
  headline: 'About Me',
  description: 'I am a fourth year Computer Science Undergraduate ' +
    'at the University of St. Andrews. I am currently on the Integrated Masters course (MSci) expected to graduate in 2023.',
  extraText: { heading: 'More About Me', text: 'I am a motivated computer science student looking for internships in the summer of 2022 for SWE roles. I am currently an incoming SWE Intern at Thought Machine, starting 5th July 2021. See my CV for other skills and experience.'},
  buttonLabel: 'View more',
  imgStart: true,
  img: require('../../images/about.svg').default,
  download: false,
  cv: require('../../files/raiyan-chowdhury-cv-mk9x.pdf'),
  alt: 'img',
  dark: true,
  primary: true,
  darkText: false,
}

export const educationObj = {
  id: 'education',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Academic Results (Hons)',
  headline: 'Education',
  description: 'On track for 1st. The University of St Andrews uses' +
      ' a 20-point scale with these boundaries. ' +
      '1:1: 16.5 - 20, ' +
      '2:1: 13.5 - 16.5, ' +
    '2:2 <16.5',
  extraText: { heading: 'My Grades', text: 'Artificial Intelligence: 15.6, Computational Complexity: 18.5, Databases: 17.0, Human Computer Interaction: 16.5, Logic and Reasoning: 18.6, Operating Systems: 16.4, SWE Team Project: 15.8.'},
  buttonLabel: 'See my academic grades',
  imgStart: true,
  img: require('../../images/education.svg').default,
  download: false,
  cv: require('../../files/raiyan-chowdhury-cv-mk9x.pdf'),
  alt: 'img',
  dark: false,
  primary: false,
  darkText: true,
}

export const cvObj = {
  id: 'cv',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Curriculum Vitae',
  headline: 'My CV',
  description: 'Download an updated copy of my CV.',
  extraText: { heading: 'My CV', text: "This shouldn't be visible"},
  buttonLabel: 'Download CV',
  imgStart: true,
  img: require('../../images/cv.svg').default,
  download: true,
  // img: require('../../files/CV-mk8x.pdf').default,
  cv: require('../../files/raiyan-chowdhury-cv-mk9x.pdf'),
  alt: 'img',
  dark: true,
  primary: true,
  darkText: false,
}