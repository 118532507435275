import styled from 'styled-components';
// import {Column1, Column2} from '../InfoSection/InfoElements'
import {MdClose} from 'react-icons/md';

// export const ModalBackground = styled.div`
//    width: 100%;
//    height: 100%;
//    background: rgba(0,0,0,0.8);
//    position: fixed; // fixed
//    display: flex;
//    justify-content: center;
//    align-items: center;
//    border-radius: 10px
// `;

export const ModalWrapper = styled.div`
  width: 540px;
  height: 320px;
  max-width: 100%;
  max-height: 100%;

  // max-width: 1100px;
  

  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #00C3D4; // #fff //01bf71
  // opacity: 0.75;
  // color: #000;
  display: grid;
  grid-template-columns: 1fr;
  // position: absolute; // fixed
  z-index: 100;
  border-radius: 10px;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 620px) {
    // width: 360px;
    // height: 240px;
    width: 22rem;
    height: 20rem;
  }
`;

export const ModalImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 10px 0 0 10px;
  background: #000;
`;

export const ModalContent = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.8;
  color: #141414;
  overflow: auto;

  p {
    margin-bottom: 1rem;
  }

  button {
    padding: 10px 24px;
    background: #141414; // #141414
    border-radius: 5px;
    color: #fff;
    border: none;
    cursor: pointer;

    &:hover {
    transition: all 0.2s ease-in-out;
      color: #00C3D4; //01bf71
  }
  }
`;

export const CloseModalButton = styled(MdClose)`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 10;

  &:hover {
    transition: all 0.2s ease-in-out;
      color: #141414;
  }

  @media screen and (max-width: 768px) {
    right: 200px;
  }
`;