import React from 'react'
import ScrollToTop from '../components/ScrollToTop'
import Contact from '../components/Contact'

const ContactPage = () => {
  return (
    <div>
      <ScrollToTop/>
      <Contact/>
    </div>
  )
}

export default ContactPage
