import React, {useState} from 'react';
import Video from '../../videos/lines.mp4';
import { cyc, LandingContainer, LandingBg, VideoBg, LandingContent, LandingH1, LandingP, LandingBtnWrapper, ArrowForward, ArrowRight } from './LandingElements';
import { Button } from '../ButtonElement';
import pdf from '../../files/raiyan-chowdhury-cv-mk9x.pdf'


const LandingSection = () => {
  const [hover, setHover] = useState(false)
  

  const onHover = () => {
    setHover(!hover)
  }

  return (
    <LandingContainer id="home">
      <LandingBg>
        <VideoBg autoPlay loop muted src={Video} type='video/mp4'/>
      </LandingBg>
      <LandingContent>
        <LandingH1>Raiyan Chowdhury</LandingH1>
        <LandingP>Software Engineer</LandingP>
        <LandingBtnWrapper>
          <Button
            as="a" href={pdf} download
            primary='true'
            dark='true'
            smooth={true}
            duration={500}
            spy={true}
            exact='true'
            >Download CV
          </Button>

          <Button to="about"
            onMouseEnter={onHover}
            onMouseLeave={onHover}
            primary='true'
            dark='true'
            smooth={true}
            duration={500}
            spy={true}
            exact='true'
            offset={-80}>Find out about me{hover ? <ArrowForward /> : <ArrowRight />}
          </Button>
</LandingBtnWrapper>
          <cyc/>
      </LandingContent>
    </LandingContainer>
  )
}

export default LandingSection
