import React, {useState} from 'react'
import { Button } from '../ButtonElement'
import Modal from '../Modal';
import pdf from '../../files/raiyan-chowdhury-cv-mk9x.pdf';


import { InfoContainer, InfoWrapper, InfoRow, Column1, Column2, TextWrapper, TopLine, Heading, Subtitle, BtnWrap, ImgWrap, Img} from './InfoElements';


const InfoSection = ({ lightBg, id, imgStart, topLine, lightText, headline, darkText, description,extraText, buttonLabel, img, download, cv, alt, primary, dark, dark2}) => {

  const [showModal, setShowModal] = useState(false);


  const openModal = () => {
    setShowModal(prev => !prev);
  }

  var button = null
  if (download === false) {
    button = <Button
      onClick={openModal}
      offset={-80}
      primary={primary ? 1 : 0}
      dark={dark ? 1 : 0}
      dark2={dark2 ? 1 : 0}
    >{buttonLabel}
    </Button>
  } else {
    button = <Button
      as="a" href={pdf} download
      offset={-80}
      primary={primary ? 1 : 0}
      dark={dark ? 1 : 0}
      dark2={dark2 ? 1 : 0}
    >{buttonLabel}
    </Button>

  }
  
  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>{topLine}</TopLine>
                <Heading lightText={lightText}>{headline}</Heading>
                <Subtitle darkText={darkText}>{description}</Subtitle>
                <BtnWrap>
              
                  {button}
                  
                </BtnWrap>
                <Modal showModal={showModal} setShowModal={setShowModal}>{extraText}</Modal>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={img} alt={alt}/>
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
      
    </>
  )
}

export default InfoSection
