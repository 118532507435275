import React from 'react';
import emailjs from 'emailjs-com';


import {
  Container,
  FormWrap,
  Icon,
  FormContent,
  Form,
  FormH1,
  FormLabel,
  FormInput,
  FormButton,
} from './ContactElements';

const Contact = () => {

  const SERVICE_ID = 'service_ujh3b6w'
  const TEMPLATE_ID = 'rc-first-web'
  const USER_ID = 'user_9RPid9zJCJmEucwiWfoKU'

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevents default refresh by the browser
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID)
      .then((result) => {
        alert("Message sent! I will get back to you shortly.", result.text);
      },
        (error) => {
          alert("An error occurred, Please try again or email me directly at raiyan.c15@gmail.com", error.text);
        });
    e.target.reset()
  }
  return (
    <>
      <Container>
        <FormWrap>
          <Icon to='/'>RC</Icon>
          <FormContent>
            <Form onSubmit={handleSubmit}>
              <FormH1>Contact Me</FormH1>
              <FormLabel htmlFor='for'>Email</FormLabel>
              <FormInput type='email' name="email" required />
              <FormLabel htmlFor='for'>Full Name</FormLabel>
              <FormInput type='text' name="from_name" required />
              <FormLabel htmlFor='for'>Company</FormLabel>
              <FormInput type='text' name="company" required />
              <FormLabel htmlFor='for'>Message Title</FormLabel>
              <FormInput type='text' name="msg_title" required />
              <FormLabel htmlFor='for'>Message</FormLabel>
              <FormInput type='text' name="message" required />
              <FormButton type='submit' >Send</FormButton>
            </Form>
          </FormContent>
        </FormWrap>
      </Container>
    </>
  );
  // return (
  //   <form className="contact-form" onSubmit={handleSubmit}>
  //     <input type="hidden" name="contact_number" />
  //     <label>Name</label>
  //     <input type="text" name="from_name" />
  //     <label>Email</label>
  //     <input type="email" name="email" />
  //     <label>Company</label>
  //     <textarea name="company" />
  //     <label>Message Title</label>
  //     <textarea name="msg_title" />
  //     <label>Message</label>
  //     <textarea name="message" />
  //     <input type="submit" value="Send" />
  //   </form>
  // );

};

export default Contact;
