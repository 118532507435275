import React from 'react'
import f1 from '../../images/f1.svg';
import website from '../../images/website.svg';
import tc from '../../images/tradecraft.svg';
import { ProjectsContainer, ProjectsH1, ProjectsWrapper, ProjectsCard, ProjectsIcon, ProjectsH2, ProjectsP } from './ProjectElements';

const Projects = () => {
  return (
    <ProjectsContainer id="projects">
      <ProjectsH1>My Projects</ProjectsH1>
      <ProjectsWrapper>
        <ProjectsCard as="a" href="https://github.com/RaiyanC/F1Predict" target="_blank" download>
          <ProjectsIcon src={f1} />
          <ProjectsH2>F1 Prediction Model</ProjectsH2>
          <ProjectsP>Modelling F1 races using ergast API to predict finishing placements for each Grand Prix.</ProjectsP>
        </ProjectsCard>
        <ProjectsCard>
          <ProjectsIcon src={website} />
          <ProjectsH2>This Website!</ProjectsH2>
          <ProjectsP>Created from scratch using React.js and styled components making using of react scroll, spring and states.</ProjectsP>
        </ProjectsCard>
        <ProjectsCard>
          <ProjectsIcon src={tc} />
          <ProjectsH2>Trading Bot (in progress)</ProjectsH2>
          <ProjectsP>Building using Python and Alpaca API, refining my strategy using trading lessons learnt in Optiver Tradecraft.</ProjectsP>
        </ProjectsCard>
        
      </ProjectsWrapper>
    </ProjectsContainer>
  )
}

export default Projects
