import styled from 'styled-components';
import {
    MdArrowDownward,
    MdKeyboardArrowDown,
} from 'react-icons/md';

export const LandingContainer = styled.div`
  background: #0c0c0c;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  height: 800px;
  position: relative;
  z-index: 1;

  :before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.6) 100%), linear-gradient(180deg, rgba(0,0,0,0.2) 0%, transparent 100%);
    z-index: 2;
  }
`;

export const LandingBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const VideoBg = styled.video`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background: #232a34;
`;

export const LandingContent = styled.div`
  z-index: 3;
  max-width: 1200px;
  position: absolute;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;


export const LandingH1 = styled.h1`
  color: #fff;
  font-size: 48px;
  text-align: center;
  
  &:hover {
    animation: color-change 3s infinite;
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }

  @keyframes color-change {
  50% { color: #00C3D4; } // 01bf71
  100% { color: #fff; }
  //100% { color: #01BF71; }
}

  @media screen and (max-width: 768px) {
    font-size: 40px;
  }

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;


export const LandingP = styled.p`
  margin-top: 24px;
  color: #fff;
  font-size: 24px;
  text-align: center;
  max-width: 600px;
  
  //&:hover {
  //  transform: scale(1.02);
  //  transition: all 0.2s ease-in-out;
  //  cursor: pointer;
  //}

  @media screen and (max-width: 768px) {
    font-size: 24px;
  }

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;

export const LandingBtnWrapper = styled.div`
  margin-top: 32px;
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center; 
  grid-template-areas: ${( `'col1 col2'`)};
`;


export const ArrowForward = styled(MdArrowDownward)`
  margin-left: 6px;
  font-size: 20px;
`;

export const ArrowRight = styled(MdKeyboardArrowDown)`
  margin-left: 6px;
  font-size: 20px;
`;