import React, { useEffect, useCallback } from 'react'
import { useSpring, animated } from 'react-spring';

import {ModalWrapper, ModalContent} from './ModalElements';

const Modal = ({ showModal, setShowModal, children }) => {

  // const modalRef = useRef();

  const openAnimation = useSpring({
    config: {
      duration: 300
    },
    opacity: showModal ? 1 : 0,
    transform: showModal ? `translateY(0%)` : `translateY(-100%)`
  })

  // const closeModal = e => {
  //   if (modalRef.current === e.target) {
  //     setShowModal(false)
  //   }
  // }

  const keyPress = useCallback(e => {
    if (e.key === 'Escape' && showModal) {
      setShowModal(false)
    }
  }, [setShowModal, showModal])

  useEffect(
    () => {
      document.addEventListener('keydown', keyPress);
      return () => document.removeEventListener('keydown', keyPress);
    }, [keyPress]);

  return (
    <>
      {showModal ? (
        // <ModalBackground >
          <animated.div style={openAnimation}>
          <ModalWrapper showModal={showModal} >
            {/* <ModalImg src={require('../../images/modal-test.jpg')} alt='camera' /> */}
              <ModalContent>
                <h2>{children.heading}</h2>
              <p>{children.text}</p>

              <button onClick={() => setShowModal(prev => !prev)}>Close</button>
            </ModalContent>
            {/* <CloseModalButton aria-label='Close modal' onClick={() => setShowModal(prev => !prev)}/> */}
            </ModalWrapper>
          </animated.div>
        // </ModalBackground>
      ) : null}
    </>
  );
};

export default Modal;
