import React from 'react';
import { FaFacebook, FaGithub, FaLinkedin } from 'react-icons/fa';
import { animateScroll as scroll } from 'react-scroll';
import { FooterContainer, FooterLink, FooterLinkItems, FooterLinkTitle, FooterLinksContainer, FooterLinksWrapper, FooterWrap, SocialMediaWrap, WebsiteRights, SocialIconLink, SocialIcons, SocialLogo, SocialMedia } from './FooterElements';

const Footer = ({toggle}) => {

  const toggleHome = () => {
    scroll.scrollToTop();
  }

  return (
    <>
      <FooterContainer>
        <FooterWrap>
          <FooterLinksContainer>
            <FooterLinksWrapper>
              <FooterLinkItems>
                <FooterLinkTitle>My Details</FooterLinkTitle>
                {/* To link off the website, use 'a' tag instead of to */}
                <FooterLink as="a" href="mailto:raiyan.c15@gmail.com">raiyan.c15@gmail.com</FooterLink>
                <FooterLink as="a" href="tel:+447449492700">0744992700</FooterLink>
              </FooterLinkItems>
              <FooterLinkItems>
                
                <FooterLinkTitle>Thanks for visiting!</FooterLinkTitle>

                <FooterLink to="about" smooth={true} duration={500} spy={true} exact='true' offset={-80}>About Me</FooterLink>
                <FooterLink to="education" smooth={true} duration={500} spy={true} exact='true' offset={-80}>Education</FooterLink>
                <FooterLink to="cv" smooth={true} duration={500} spy={true} exact='true' offset={-80}>CV</FooterLink>
                <FooterLink to="projects" smooth={true} duration={500} spy={true} exact='true' offset={-80}>Projects</FooterLink>
                  <FooterLink as="a" href="/contact">Contact Me</FooterLink>
              </FooterLinkItems>
              
            </FooterLinksWrapper>
          </FooterLinksContainer>
          <SocialMedia>
            <SocialMediaWrap>
              <SocialLogo to='/' onClick={toggleHome}>Raiyan Chowdhury</SocialLogo>
              
            <WebsiteRights>RC - {new Date().getFullYear()} All Rights Reserved.</WebsiteRights>
            <SocialIcons>
                <SocialIconLink href="https://www.linkedin.com/in/raiyan-c/" target="_blank" aria-label="LinkedIn"><FaLinkedin />
            </SocialIconLink>
                <SocialIconLink href="https://github.com/RaiyanC" target="_blank" aria-label="Github"><FaGithub />
            </SocialIconLink>
                <SocialIconLink href="https://www.facebook.com/raiyan.chowdhury.5" target="_blank" aria-label="Facebook"><FaFacebook />
                </SocialIconLink>
              </SocialIcons>
            </SocialMediaWrap>
          </SocialMedia>
        </FooterWrap>
      </FooterContainer>
    </>
  )
}

export default Footer
